import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { Input } from '../shared/form-control/Input';
import PageLoader from '../shared/page-loader/PageLoader';
import { currentUserAtom } from '../../recoil/atoms/Auth';
import { Field, FieldProps, Formik, FormikHelpers, FormikState } from 'formik';
import { UserProfile } from '../../models/AuthModels';
import useSchema from '../../schema';
import { Heading, HeadingSize } from '../shared/text/Heading';
import Button, { ButtonType } from '../shared/form-control/Button';
import DropdownSelect from '../shared/form-control/DropdownSelect';
import { supportedLanguages } from '../../types/Languages';
import AuthService from '../../services/AuthService';
import { ToastType, useToasts } from '../../contexts/ToastContext';
import StorageService from '../../services/StorageService';
import CurrentUserService from '../../services/CurrentUserService';
import i18next from 'i18next';

const Profile: FC = () => {
  const { t } = useTranslation(['user-settings', 'common']);
  const [isLoading, setIsLoading] = useState(false);
  const [currentUser, setCurrentUser] = useRecoilState(currentUserAtom);
  const schemas = useSchema();
  const resetFormRef = useRef<(nextState?: Partial<FormikState<UserProfile>> | undefined) => void | null>();
  const toasts = useToasts();

  const [initialValues, setInitialValues] = useState<UserProfile>({} as UserProfile);

  const languageOptions = useMemo(() => {
    return supportedLanguages.map((lang) => ({
      id: lang.id,
      value: lang.value,
      text: t(`common:languages.${lang.id}`),
    }));
  }, [t]);

  useEffect(() => {
    setInitialValues({
      emailAddress: currentUser?.email || '',
      firstName: currentUser?.firstName || '',
      lastName: currentUser?.lastName || '',
      jobTitle: currentUser?.jobTitle || '',
      languageCode: currentUser?.language || '',
      phoneNumber: currentUser?.phoneNumber || '',
    });
  }, [currentUser?.email, currentUser?.firstName, currentUser?.jobTitle, currentUser?.language, currentUser?.lastName, currentUser?.phoneNumber]);

  const saveProfile = (values: UserProfile, { setSubmitting }: FormikHelpers<UserProfile>) => {
    setIsLoading(true);
    CurrentUserService.updateProfile(values)
      .then((res) => {
        StorageService.setLang(res.data.language);
        setCurrentUser((prev) => prev && { ...prev, ...res.data });
        res.data.language && i18next.changeLanguage(res.data.language);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setSubmitting(false);
        resetFormRef.current && resetFormRef.current();
        setIsLoading(false);
      });
  };

  const resetPassword = () => {
    if (currentUser?.email) {
      AuthService.forgotPassword(currentUser?.email).then((res) => {
        if (res.data) {
          toasts.addToast({
            title: t('user-settings:details.toasts.reset-email.title'),
            description: t('user-settings:details.toasts.reset-email.description'),
            type: ToastType.INFO,
            expiresInMs: 5000,
          });
        } else {
          toasts.addToast({
            title: t('user-settings:details.toasts.reset-email-failed.title'),
            description: t('user-settings:details.toasts.reset-email-failed.description'),
            type: ToastType.ERROR,
            expiresInMs: 5000,
          });
        }
      });
    }
  };

  return (
    <PageLoader loading={isLoading}>
      <Formik enableReinitialize validateOnChange validationSchema={schemas.user.maintainUser} onSubmit={saveProfile} initialValues={initialValues}>
        {(props) => {
          const { handleSubmit, resetForm, values, handleChange, handleBlur, errors, dirty, isValid, isInitialValid, isSubmitting } = props;
          resetFormRef.current = resetForm;
          const disabled = !dirty || !(isValid || isInitialValid) || isSubmitting;
          const handleSaveProfile = () => {
            handleSubmit();
          };
          return (
            <form className="my-6 flex h-full flex-col">
              <div className="flex justify-between">
                <div>
                  <Heading size={HeadingSize.H3}>{t('user-settings:details.heading')}</Heading>
                </div>
                <div className="flex items-start gap-4">
                  <Button
                    data-cy="reset-password"
                    title={t('user-settings:details.buttons.password-reset')}
                    type={ButtonType.PRIMARY}
                    onClick={resetPassword}
                  >
                    {t('user-settings:details.buttons.password-reset')}
                  </Button>
                  <Button
                    data-cy="save-user"
                    title={t('user-settings:details.buttons.save')}
                    disabled={disabled}
                    type={ButtonType.PRIMARY}
                    onClick={handleSaveProfile}
                  >
                    {t('user-settings:details.buttons.save')}
                  </Button>
                </div>
              </div>
              <div className="border-gray-5 flex flex-wrap border-b-2 pb-8">
                <div className="w-1/2 pr-4">
                  <Input
                    data-cy="firstname"
                    name="firstName"
                    label={t('user-settings:details.firstname')}
                    placeholder={t('user-settings:details.firstname')}
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.firstName}
                  />
                </div>
                <div className="w-1/2 pl-4">
                  <Input
                    data-cy="lastname"
                    name="lastName"
                    label={t('user-settings:details.lastname')}
                    placeholder={t('user-settings:details.lastname')}
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.lastName}
                  />
                </div>
                <div className="w-1/2 pr-4">
                  <Input
                    data-cy="email"
                    name="email"
                    label={t('user-settings:details.email')}
                    placeholder={t('user-settings:details.email')}
                    value={currentUser?.email}
                    disabled
                  />
                </div>
                <div className="w-1/2 pl-4">
                  <Field id="languageCode" name="languageCode" type="string">
                    {({ field: { value }, form: { setFieldValue } }: FieldProps) => (
                      <DropdownSelect
                        data-cy="pref-language"
                        label={t('user-settings:details.prefered-language')}
                        onChange={(data) => {
                          setFieldValue('languageCode', data.value as string);
                        }}
                        options={languageOptions}
                        value={languageOptions.find((x) => x.id === value)}
                      />
                    )}
                  </Field>
                </div>
                <div className="w-1/2 pr-4">
                  <Input
                    data-cy="contact-number"
                    name="phoneNumber"
                    label={t('user-settings:details.contact-number')}
                    placeholder={t('user-settings:details.contact-number')}
                    value={values.phoneNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.phoneNumber}
                    maxLength={20}
                  />
                </div>
                <div className="w-1/2 pl-4">
                  <Input
                    data-cy="job-title"
                    name="jobTitle"
                    label={t('user-settings:details.job-title')}
                    placeholder={t('user-settings:details.job-title')}
                    value={values.jobTitle}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.jobTitle}
                  />
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </PageLoader>
  );
};

export default Profile;
